<template>
    <div class="pageMain">
      <!-- 企业备案 -->
      <div class="content">
        <!-- 左边列表 -->
        <CustomTables
          :data="tableData"
          :total="total"
          class="tableHear"
          :headerCellStyle="headerCellStyle"
          @handleCurrentChange="handleCurrentChange"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChanges="handleCurrentChangesProject"
          :titleIcon="true"
        >
          <template slot="search">
            <SearchLeft
              @search="search"
              @reset="reset"
              :iconShow="true"
              :infoShow="true"
              info="项目列表"
            >
              <el-form :inline="true" :model="searchForm">
                <el-form-item class="formItem">
                  <el-input
                    @input="change"
                    v-model="searchForm.projName"
                    size="small"
                    placeholder="项目"
                  ></el-input>
                </el-form-item>
              </el-form>
            </SearchLeft>
          </template>
  
          <el-table-column type="index" label="排序" width="50" align="center">
          </el-table-column>
          <el-table-column prop="projName" label="项目名称" align="center" show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            prop="deviceId"
            label="设备编号"
            align="center"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="constPm10"
            label="PM10(ug/m3)"
            align="center"
           
          >
          </el-table-column>
        </CustomTables>
        <div class="tableBox">
        
          <CustomTables
            :data="tableData3"
            :headerCellStyle="headerCellStyle"
        
            :total="total3"
            title="数据列表"
            @selectionChange="handleSelectionChanges"
            @handleCurrentChange="handleCurrentChange3"
            @handleSizeChange="handleSizeChange3"
          >
            <template slot="tabs">
              <el-tabs v-model="activeName2" @tab-click="handleClick2">
                <el-tab-pane
                  v-for="(tab, index) in tabs2"
                  :key="tab.name"
                  :label="tab.label"
                  :name="tab.name"
                >
                </el-tab-pane>
              </el-tabs>
            </template>
            <el-table-column type="selection" align="center" width="55">
            </el-table-column>
            <el-table-column prop="constTime" label="记录时间" align="center"  show-overflow-tooltip>
            </el-table-column>
            <!-- <el-table-column prop="endTime" label="结束时间" align="center"  show-overflow-tooltip>
            </el-table-column> -->
            <el-table-column prop="pm25" label="PM2.5" align="center">
            </el-table-column>
            <el-table-column prop="pm10" label="PM10" align="center">
            </el-table-column>
            <el-table-column prop="noise" label="噪音" align="center">
            </el-table-column>
            <!-- <el-table-column prop="name" label="最大角度"  align="center"> </el-table-column>
              <el-table-column prop="name" label="最小角度"  align="center"> </el-table-column>
              <el-table-column prop="name" label="开始幅度"  align="center"> </el-table-column>
              <el-table-column prop="name" label="结束幅度"  align="center"> </el-table-column> -->
            <el-table-column prop="tsp" label="TSP" align="center">
            </el-table-column>
            <el-table-column prop="temp" label="温度" align="center">
            </el-table-column>
          
            <el-table-column prop="humidity" label="湿度" align="center">
            </el-table-column>
              <el-table-column prop="atmosphere" label="气压"  align="center"> </el-table-column>
              <el-table-column prop="windDirection" label="风向"  align="center"> </el-table-column>
            <el-table-column prop="co2" label="二氧化碳" align="center">
            </el-table-column>
            <el-table-column prop="rainfall" label="降雨量" align="center">
            </el-table-column>
            <el-table-column prop="no2" label="二氧化氮" align="center">
            </el-table-column>
            <el-table-column prop="so2" label="二氧化硫" align="center">
            </el-table-column>
            <el-table-column prop="ch2o" label="甲醛" align="center">
            </el-table-column>
            <el-table-column prop="windSpeed" label="风速" align="center">
            </el-table-column>
            <!-- <el-table-column label="回放" align="center" width="100">
              <template slot-scope="scope">
                <span @click="detailItem(scope.row)" class="detail">查看</span>
              </template>
            </el-table-column> -->
          </CustomTables>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import CustomTables from "../../../components/common/CustomTables";
  import SearchHead from "../../../components/common/SearchHead";
  import SearchLeft from "../../../components/common/SearchLeft.vue";
  import monitor from "../../../api/modules/monitor";
  export default {
    components: { CustomTables, SearchHead, SearchLeft },
    name: "monitorAlarm",
    data() {
      return {
        activeName: "0",
        tableData: [],
        tableData2: [],
        tableData3: [],
        headerCellStyle: {
          backgroundColor: "#f3f2f7",
          color: "#373628",
        },
        searchForm: {
          currPage: 1,
          pageSize: 10,
        },
        searchForm2: {
          currPage: 1,
          pageSize: 10,
        },
        searchForm3: {
          currPage: 1,
          pageSize: 10,
        },
        activeName: "0",
        activeName2: "1",
        tabs: [
          {
            label: "塔机",
            name: "tower",
          },
          {
            label: "升降机",
            name: "towers",
          },
        ],
        tabs2: [
          {
            label: "今天",
            name: "1",
          },
          {
            label: "本周",
            name: "2",
          },
          {
            label: "本月",
            name: "3",
          },
         
        ],
        total: 0,
        total2: 0,
        total3: 0,
        count: "",
        deviceNo: "",
        projId: "",
        deviceImei: "",
        deviceId:''
      };
    },
    mounted() {
      this.getList();
    },
    methods: {
      handleCurrentChangesProject(row) {
      
        this.deviceId =row.deviceId
        this.activeName2='1'
        this.getWorkListByPages(row.deviceId, this.activeName2);
      },
      handleCurrentChange(pageIndex) {
        this.searchForm.currPage = pageIndex;
        this.getList();
      },
      handleSizeChange(pageSize) {
        this.searchFormpageSize = pageSize;
        this.searchForm.currPage = 1;
        this.getList();
      },
    
      handleCurrentChange3(pageIndex) {
        this.searchForm3.currPage = pageIndex;
        this.getList();
      },
      handleSizeChange3(pageSize) {
        this.searchForm3.pageSize = pageSize;
        this.searchForm3.currPage = 1;
        this.getList();
      },
      getList() {
        monitor.getInfoByPage(this.searchForm).then((res) => {
          this.total = res.data.total;
          this.tableData = res.data.records;
          if (this.tableData.length > 0) {
            this.deviceId = this.tableData[0].deviceId;
            this.getWorkListByPages(this.tableData[0].deviceId,1)
            // this.ProjectDeviceCount(this.tableData[0].projId);
          } else {
            // this.ProjectDeviceCount()
          }
        });
      },
      change(e) {
        console.log("e", e);
        this.searchForm.projName = e;
        this.searchForm.pageNum = 1;
        this.searchForm.pageSize = 10;
        this.getList(this.searchForm);
      },

      getWorkListByPages(deviceId, workTime) {
        let params = {
          currPage: this.searchForm3.currPage,
          pageSize: this.searchForm3.pageSize,
          deviceId: deviceId,
          workTime: workTime,
        };
        monitor.getInfoListByPage(params).then((res) => {
          this.total3 = res.data.total;
          this.tableData3 = res.data.records;
        });
      },
      search() {
        this.searchForm.pageNum = 1;
        this.searchForm.pageSize = 10;
        this.getList(this.searchForm);
      },
      reset() {
        this.searchForm = {
          pageNum: 1,
          pageSize: 10,
        };
        this.getList();
      },
    
      handleClick2(tab, event) {
        this.searchForm3.currPage = 1;
        console.log(tab, event);
        this.getWorkListByPages(this.deviceId, tab.paneName);
      },
      detailItem() {},
  
      handleSelectionChange() {},
      handleSelectionChanges() {},
    },
  };
  </script>
  
  <style scoped lang="less">
  .content {
    display: flex;
    justify-content: space-between;
  }
  .tableBox {
    // margin-left: 10px;
    width: 72%;
  }
  .tableHear {
    width: 25%;
    overflow: auto
    // background:#e4ebf1
  }
  .title {
    margin-bottom: 10px;
  color: #000;
  }
  
  .formItem{
    margin-bottom: 0px;
  }
  .tables{
    margin-bottom: 10px;
  }
  </style>
  